import * as React from 'react';
import Settings from '../../../../../platform/services/settings';
import AboutUsImage from '../../../../../assets/images/mds.png';

const AboutTheProject = React.memo(() => <>
  <div
    className="I-about-image"
    style={{ background: `url("${AboutUsImage}") center/contain no-repeat`}}
  />
  <div className="I-about-texts">
    <p>{Settings.translations.about_the_project_text}</p>
  </div>
</>);

export default AboutTheProject;