import * as React from 'react';

import { LanguageEnum } from './enums';

import EN from '../../assets/translations/en';
import RU from '../../assets/translations/ru';
import AM from '../../assets/translations/am';

import English from '../../assets/images/english.png';
import Russian from '../../assets/images/russian.png';
import Armenian from '../../assets/images/armenian.png';
import { ProductSortEnum } from '../api/product';
import { enumToSelectOptions } from '../services/helper';

export const SortByDropdown = () => enumToSelectOptions<ProductSortEnum>(ProductSortEnum);
export const LanguagesDropdown = [
  {
    name: <><img src={English} /> {EN.language_label}</>,
    value: LanguageEnum.English,
  },
  {
    name: <><img src={Russian} /> {RU.language_label}</>,
    value: LanguageEnum.Russian,
  },
  {
    name: <><img src={Armenian} /> {AM.language_label}</>,
    value: LanguageEnum.Armenian,
  },
];