import * as React from 'react';

export const currency = <>&#1423;</>;
export const paginationPageLimit = 4;
export const googlePlacesTypes = ['geocode', 'establishment'];
export const geolocation = { lat: 40.180972, lng: 44.513046 };
export const googlePlacesRestrictions = { country: ['am', 'az'] };
//* az - only for choosing places in Arcakh.
//! Remove it after by official data Arcakh will be part of Armenia

export const countryCode = '374';
export const months = [
  'Jan', 'Feb',
  'Mar', 'Apr', 'May',
  'Jun', 'Jul', 'Aug',
  'Sep', 'Oct', 'Nov',
  'Dec',
];