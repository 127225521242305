import * as React from "react";
import ROUTES from "../../platform/constants/routes";
import { byRoute } from "../../platform/decorators/routes";

import Settings from "../../platform/services/settings";
import RequestModal from "../request/components/request-modal";
import Promotions from "./components/promotions";
import PopularCategories from "./components/popular-categories";
import Proposal from "./components/proposal";
import BestSelling from "./components/best-selling";
import Brands from "./components/brands";
import MainCategories from "./components/main-categories";
import Storage from "../../platform/services/storage";
import HelperComponent from "../../platform/classes/helper-component";
import { LanguageEnum } from "../../platform/constants/enums";
import { LanguagesDropdown } from "../../platform/constants/dropdowns";

import Main from "../../assets/images/main.png";
// import RequestBackground2 from '../../assets/images/request_background_2.jpg';

import MobileRequestBackground1 from "../../assets/images/mobile_request_background_1.jpg";
import MobileRequestBackground2 from "../../assets/images/mobile_request_background_2.jpg";
// import MobileRequestBackground1new from '../../assets/images/newYearHome1.jpg';
// import MobileRequestBackground2new from '../../assets/images/newYearHome2.jpg';

import "./style.scss";
import Footer from "../../components/footer/index";
import CartManager from "../cart/services/manager";
import LoaderContent from "src/components/loader-content";

// import { ContentProperty } from 'csstype';
// import { string } from 'prop-types';
// import { stringify } from 'querystring';

interface IState {
  requestOpen: boolean;
}

@byRoute([ROUTES.HOME.MAIN, ROUTES.HOME.FAIL, ROUTES.HOME.SUCCESS])
class Home extends HelperComponent<{}, IState> {
  public state: IState = { requestOpen: false };

  public bannerImage: string;

  public leng = LanguagesDropdown;

  public armLeng = LanguageEnum.Armenian;
  public IosLink = Settings.appStoreURL;
  public androidLink = Settings.googlePlayURL;

  public componentDidMount() {
    this.checkLanguage();
    this.IdramNotificationSuccess();
  }

  public checkLanguage = () => {
    if (this.armLeng === this.armLeng) {
      document.body.classList.add("armenianFont");
    } else {
      document.body.classList.remove("armenianFont");
    }
  };

  private get welcomeText() {
    const hours = new Date().getHours();
    if (hours >= 4 && hours < 12) return Settings.translations.good_morning;
    else if (hours >= 12 && hours < 18)
      return Settings.translations.good_afternoon;
    else return Settings.translations.good_evening;
  }

  public IdramNotificationSuccess = async () => {
    const alertify = await import("alertifyjs");
    if (window.location.href.includes("fail")) {
      window.routerHistory.push("/cart");
      setTimeout(() => {
        alertify.error(
          Settings.translations.notification_texts.order_idram_failed.title
        );
      }, 1000);
    }
    if (window.location.href.includes("success")) {
      window.routerHistory.push("/profile/my-orders");
      setTimeout(() => {
        alertify.success(Settings.translations.order_create_success);
      }, 1000);
      CartManager.Clear();
    }
  };

  public render() {
    return (
      <section className="I-G-page I-home-page">
        <div className="I-home-request">
          <div className="I-home-left">
            <div className="I-clip-div">
              {Settings.translations.home_banner}
            </div>
          </div>
          <div
            className="I-background"
            style={{ background: `url(${Main}) center/cover ` }}
          />
        </div>
        <MainCategories />
        <Promotions />
        <BestSelling />
        {/* <PopularCategories /> */}

        <Proposal />
        <Brands />
        <div className="mobile-footer mobile-footer-home">
          <Footer />
        </div>
      </section>
    );
  }
}

export default Home;
