import RouteService from '../services/routes';

const PROFILE_ROUTES = RouteService.buildRouteContext('/profile', {
  MAIN: '',
  MY_ORDERS: '/my-orders',
  MY_COMPANY: '/my-company',
  MY_REQUESTS: '/my-requests',
  MY_ADDRESSES: '/my-addresses',
  NOTIFICATIONS: "/notifications",
});

const PRODUCTS_ROUTES = RouteService.buildRouteContext('/products', {
  LIST: '',
  DETAILS: '/details/:id',
  PROPOSAL: '/proposal',
  
});

const WISH_LIST_ROUTES = RouteService.buildRouteContext('/wish-list', {
  MAIN: '',
  INVITATION: '/invitation/:code',
});

const HOME_ROUTES = RouteService.buildRouteContext('/', {
  MAIN: '',
  FAIL: 'fail',
  SUCCESS: 'success',
});

export default {
  FAQ: '/faq',
  HOME: HOME_ROUTES,
  CART: '/cart',
  ABOUT: '/about',
  PROFILE: PROFILE_ROUTES,
  VACANCY: '/vacancy',
  CONTACT: '/contact',
  PRODUCTS: PRODUCTS_ROUTES,
  BUSINESS: '/business',
  CONTRACT: '/contract',
  WISH_LIST: WISH_LIST_ROUTES,
  EXCHANGE_RETURN: '/exchange-return',
  CONFIDENTIALITY_CONDITIONS: '/conditions',
};