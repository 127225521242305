import * as React from "react";
import { NavLink, Link } from "react-router-dom";
import URLSearchParams from "@ungap/url-search-params";

import ROUTES from "../../platform/constants/routes";
import Login from "../../modules/login";
import Categories from "./components/categories";
import Settings from "../../platform/services/settings";
import Storage from "../../platform/services/storage";
import WishController from "../../platform/api/wish";
import ProfileMenu from "./components/profile-menu";
import Search from "./components/search";
import { ICachedCategories } from "../../platform/api/category";
import { ICategoryList } from "./constants/interfaces";

import { IAuthQueryData } from "../../modules/login/constants/interfaces";
import { VerifyTypeEnum } from "../../modules/login/constants/enums";
import CartManager from "../../modules/cart/services/manager";
import GuestWishList from "../../modules/wish-list/services/guest";
import HelperComponent from "../../platform/classes/helper-component";
import Screen from "../screen";

import Logo from "../../assets/images/mds.png";

import "./style.scss";

interface IState {
  categoryLists: ICategoryList[];
  cachedCategories: ICachedCategories[];
  loginOpen: boolean;
  categoriesOpen: boolean;
  profileMenuOpen: boolean;
  queryData: IAuthQueryData | null;
  search: string;
  mobileOpen: boolean;
  cartBadge: number;
  wishListBadge: number | null;
  storePath: string;
  isDownloadApp: boolean;

  searchField: boolean;
}

class Header extends HelperComponent<{}, IState> {
  public state: IState = {
    loginOpen: false,
    categoryLists: [],
    cachedCategories: [],
    categoriesOpen: false,
    profileMenuOpen: false,
    queryData: null,
    storePath: "",
    search: "",
    mobileOpen: false,
    cartBadge: CartManager.Items.length,
    wishListBadge: !Storage.profile ? GuestWishList.Ids.length : null,
    isDownloadApp: false,
    searchField: true,
  };

  public componentDidMount() {
    Storage.profile && this.wishListModify();
    !Settings.token && this.checkForAuthData();
    window.routerHistory.listen(() =>
      this.safeSetState({
        categoryLists: [],
        categoriesOpen: false,
      })
    );

    // window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("cartmodify", this.cartModify);
    window.addEventListener("wishlistmodify", this.wishListModify);
    window.addEventListener("addNewList", this.wishListModify);
  }

  public componentWillUnmount() {
    super.componentWillUnmount();
    window.removeEventListener("cartmodify", this.cartModify);
    window.removeEventListener("wishlistmodify", this.wishListModify);
    window.removeEventListener("addNewList", this.wishListModify);
  }

  private cartModify = () =>
    this.safeSetState({ cartBadge: CartManager.Items.length });
  private wishListModify = async () => {
    if (!Storage.profile) {
      this.safeSetState({ wishListBadge: GuestWishList.Ids.length });
    }
    if (Storage.profile) {
      const { data } = await WishController.List();

      this.safeSetState({ wishListBadge: data.length });
    }
  };

  private checkForAuthData = () => {
    const query = new URLSearchParams(window.location.search);
    const data = {
      email: query.get("email"),
      code: query.get("code"),
      type: +query.get("type"),
    };

    const typeValid = Object.keys(VerifyTypeEnum).find(
      (item) => VerifyTypeEnum[item] === data.type
    );
    data.email &&
      data.code &&
      typeValid &&
      this.safeSetState({ queryData: data, loginOpen: true });
  };

  private openCategories = () => this.safeSetState({ categoriesOpen: true });
  private closeCategories = (e?: MouseEvent) => {
    e && e.stopPropagation();
    this.safeSetState({ categoriesOpen: false });
  };

  private toggleLogin = () => {
    const { loginOpen } = this.state;
    if (loginOpen) this.safeSetState({ loginOpen: false, queryData: null });
    else this.safeSetState({ loginOpen: true });
  };

  private openProfileMenu = () => {
    this.safeSetState({ profileMenuOpen: true });
  };

  private closeProfileMenu = (e: MouseEvent | React.SyntheticEvent) => {
    e.stopPropagation();
    this.safeSetState({ profileMenuOpen: false, mobileOpen: false });
  };

  private changeCategoryLists = (categoryLists: ICategoryList[]) =>
    this.safeSetState({ categoryLists });
  private changeCachedCategories = (cachedCategories: ICachedCategories[]) =>
    this.safeSetState({ cachedCategories });

  private toggleMobileHeader = () => {
    const { mobileOpen } = this.state;
    this.setState({ mobileOpen: !mobileOpen });
  };

  private onHideLink = () => {
    this.setState({
      isDownloadApp: false,
    });
    document.body.classList.remove("mobile-padding");
  };
  // public searchRow: any;

  public isAppDownload = () => {
    document.body.classList.add("mobile-padding");
  };

  private Mobile = () => {
    const {
      cartBadge,
      wishListBadge,
      mobileOpen,
      profileMenuOpen,
    } = this.state;

    return (
      <header className="I-header-mobile">

        <div className="I-header-top">
          <Link
            to={ROUTES.HOME.MAIN}
            className="I-logo"
            onClick={(e) => e.stopPropagation()}
          >
            <img src={Logo} alt="logo" />
          </Link>
          {!mobileOpen && (
            <>
              <Search
                fieldPosition={`I-header-search ${
                  this.state.isDownloadApp ? "more-top" : ""
                }`}
                fieldAnimation={
                  !this.state.searchField ? "search-field-animation" : ""
                }
              />

              <div className="I-menu-icons">
                <Link className="I-label-item" to={ROUTES.WISH_LIST.MAIN}>
                  <i className="icon-wishlist" />
                  {!!wishListBadge && (
                    <span className="I-badge">
                      {wishListBadge > 99 ? "9+" : wishListBadge}
                    </span>
                  )}
                </Link>

                <Link className="I-label-item" to={ROUTES.CART}>
                  <i className="icon-cart" />
                  {!!cartBadge && (
                    <span className="I-badge">
                      {cartBadge > 99 ? "9+" : cartBadge}
                    </span>
                  )}
                </Link>

                {!Settings.token ? (
                  <div className="I-label-item" onClick={this.toggleLogin}>
                    <i className="icon-user" />
                  </div>
                ) : (
                  <div className="I-label-item" onClick={this.openProfileMenu}>
                    <i className="icon-user" />
                  </div>
                )}
              </div>
            </>
          )}

          {/* <span
            className={`I-burger ${mobileOpen ? "I-burger-open" : ""}`}
            onClick={this.toggleMobileHeader}
          >
            <span />
            <span />
            <span />
          </span> */}
        </div>
        {mobileOpen && (
          <div className="I-header-bottom I-header-bottom-mobile">
            <Link to={ROUTES.ABOUT} onClick={this.toggleMobileHeader}>
              {Settings.translations.about_us}
            </Link>
            <Link to={ROUTES.BUSINESS} onClick={this.toggleMobileHeader}>
              {Settings.translations.business}
            </Link>
            {/* <Link to={ROUTES.VACANCY}>{Settings.translations.vacancy}</Link> */}
            {/* <Link to={ROUTES.CONTACT} onClick={this.toggleMobileHeader}>
              {Settings.translations.contact_us}
            </Link> */}
            <div>
              <Link
                className="I-label-item"
                to={ROUTES.WISH_LIST.MAIN}
                onClick={this.toggleMobileHeader}
              >
                <i className="icon-wishlist" />
                {!!wishListBadge && (
                  <span className="I-badge">
                    {wishListBadge > 99 ? "9+" : wishListBadge}
                  </span>
                )}
                {Settings.translations.wish_list}
              </Link>
              <Link
                className="I-label-item"
                to={ROUTES.CART}
                onClick={this.toggleMobileHeader}
              >
                <i className="icon-cart" />
                {!!cartBadge && (
                  <span className="I-badge">
                    {cartBadge > 99 ? "9+" : cartBadge}
                  </span>
                )}
              </Link>

              {!Settings.token ? (
                <div className="I-label-item" onClick={this.toggleLogin}>
                  <i className="icon-user" />
                </div>
              ) : (
                <div className="I-label-item" onClick={this.openProfileMenu}>
                  <i className="icon-user" />
                </div>
              )}
            </div>
          </div>
        )}
        {!!Storage.profile && profileMenuOpen && (
          <ProfileMenu onClose={this.closeProfileMenu} />
        )}
      </header>
    );
  };

  public render() {
    const {
      profileMenuOpen,
      loginOpen,
      categoriesOpen,
      categoryLists,
      cachedCategories,
      queryData,
      cartBadge,
      wishListBadge,
    } = this.state;

    return (
      <>
        <Screen.Tablet>
          {(matches: boolean) =>
            matches ? (
              <this.Mobile />
            ) : (
              <header>
                <div className="I-header-top">
                  <Link to={ROUTES.HOME.MAIN} className="I-logo">
                    <img src={Logo} alt="logo" />
                  </Link>
                  <Search
                    fieldPosition={`I-header-search ${
                      this.state.isDownloadApp ? "more-top" : ""
                    }`}
                    fieldAnimation={
                      !this.state.searchField ? "search-field-animation" : ""
                    }
                  />
                  <Link className="I-label-item" to={ROUTES.WISH_LIST.MAIN}>
                    <i className="icon-wishlist" />
                    {!!wishListBadge && (
                      <span className="I-badge">
                        {wishListBadge > 99 ? "9+" : wishListBadge}
                      </span>
                    )}
                  </Link>
                  <Link className="I-label-item" to={ROUTES.CART}>
                    <i className="icon-cart" />
                    {!!cartBadge && (
                      <span className="I-badge">
                        {cartBadge > 99 ? "9+" : cartBadge}
                      </span>
                    )}
                  </Link>
                  {!Settings.token ? (
                    <div className="I-label-item" onClick={this.toggleLogin}>
                      <i className="icon-user" />
                    </div>
                  ) : (
                    <div
                      className="I-label-item"
                      onClick={this.openProfileMenu}
                    >
                      <i className="icon-user" />
                    </div>
                  )}
                </div>
                <div className="I-header-bottom">
                  <a onClick={this.openCategories}>
                    <span
                      className={`I-burger ${
                        categoriesOpen ? "I-burger-open" : ""
                      }`}
                    >
                      <span />
                      <span />
                      <span />
                    </span>
                    {Settings.translations.all_categories}
                  </a>
                  <NavLink to={ROUTES.ABOUT} activeClassName="Q-active">
                    {Settings.translations.about_us}
                  </NavLink>
                  {/* <NavLink to={ROUTES.BUSINESS} activeClassName="Q-active">
                    {Settings.translations.business}
                  </NavLink> */}
                  {/* <NavLink to={ROUTES.VACANCY} activeClassName="Q-active">{Settings.translations.vacancy}</NavLink> */}
                  <NavLink to={ROUTES.CONTACT} activeClassName="Q-active">
                    {Settings.translations.contact_us}
                  </NavLink>
                </div>
                {categoriesOpen && (
                  <Categories
                    categoryLists={categoryLists}
                    cachedCategories={cachedCategories}
                    onChange={this.changeCategoryLists}
                    onCachedChange={this.changeCachedCategories}
                    onClose={this.closeCategories}
                  />
                )}
                {!!Storage.profile && profileMenuOpen && (
                  <ProfileMenu onClose={this.closeProfileMenu} />
                )}
              </header>
            )
          }
        </Screen.Tablet>
        {loginOpen && <Login onClose={this.toggleLogin} data={queryData} />}
      </>
    );
  }
}

export default Header;
